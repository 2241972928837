import autoComplete from "@tarekraafat/autocomplete.js";

const autoCompleteJS = new autoComplete({
    selector: "#search-ac",
    placeHolder: "Введите название товара...",
    data: {
        src: async function () {
            const source = await fetch(
                $('#search-ac').data('source')
            );
            return await source.json();
        },
        key: ['key']
    },
    resultsList: {
        noResults: (list, query) => {
            // Create "No Results" message list element
            const message = document.createElement("li");
            message.setAttribute("class", "no_result");
            // Add message text content
            message.innerHTML = `<span>Ничего не найдено по запросу "${query}"</span>`;
            // Add message list element to the list
            list.appendChild(message);
        },
        maxResults: 15
    },
    resultItem: {
        highlight: {
            render: true
        }
    },
    onSelection: (feedback) => {
        // Replace Input value with the selected value
        document.querySelector("#search-ac").value = feedback.selection.value[feedback.selection.key];
        // Console log autoComplete data feedback
        window.location.replace(feedback.selection.value['link']);
    }
});
